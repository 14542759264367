import React , {useEffect}from 'react'
import '../styles/services.css';
import Aos from 'aos';
import "aos/dist/aos.css"
import Card from './card';

  
function Services() {
  useEffect(() => {
    Aos.init({duration:2000})
  }, [])
  return (
    <div className='services sm:h-auto md:h-auto h-auto pb-20'  id='services'>

      <div className='service_text flex justify-center items-center' data-aos="fade-up">
        <p className='md:text-3xl sm:text-3xl text-xl font-bold text-gray-700 md:w-1/4 sm:w-2/4 w-2/3' >WE PROVIDE A WIDE VARIETY OF IT SERVICES</p>
      </div>

      <div className='cards sm:p-4 p-4 md:p-16 sm:flex-wrap sm:flex-row flex-col md:flex-row md:flex-wrap '>
      <Card/>

      </div>



    </div>
  )
}

export default Services
