import React , {useEffect , useState}from 'react'
import '../styles/card.css';
import Aos from 'aos';
import "aos/dist/aos.css"
import img from '../images/uiux.svg'
  
function Card() {
  useEffect(() => {
    Aos.init({duration:2000})
  }, [])

  const cards = [ 

  {title:'UI & UX Design', text: 'Designing your products professionally, simple and interactive' , image:img , key:1},
  {title:'UI & UX Design', text: 'Designing your products professionally, simple and interactive' , image:img , key:2},
  {title:'UI & UX Design', text: 'Designing your products professionally, simple and interactive' , image:img , key:3},
  {title:'UI & UX Design', text: 'Designing your products professionally, simple and interactive' , image:img , key:4},
  {title:'UI & UX Design', text: 'Designing your products professionally, simple and interactive' , image:img , key:5},
  {title:'UI & UX Design', text: 'Designing your products professionally, simple and interactive' , image:img , key:5}

]

  return (
    

        
    
  cards.map( card => {
    return(
        <div className='card  w-[95%] h-[12rem] sm:h-[15rem] md:h-[15rem] sm:w-[80%] md:w-[30%] m-4 sm:m-6 md:m-2 md:mb-8' data-aos="fade-up" key={card.key}>
          <img src={card.image} alt="" className='h-1/3 w-1/3 md:h-[65%]'/>
          <div className='card_text text-gray-600 h-1/3 flex flex-col justify-center items-center'>
             <p className='text-2xl m-1'>{card.title}</p>
             <p className='text-sm'>{card.text}</p>
         </div>
         
        </div>
  )} )

  )
}

export default Card
