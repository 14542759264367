import React,{useEffect} from 'react'
import '../styles/Header.css';
import Aos from 'aos';
import "aos/dist/aos.css"
import header_image from "../images/header-laptop-1.svg"
import  future from '../images/build-1.svg'
function Header() {

  useEffect(() => {
    Aos.init({duration:2000})
  }, [])
  

  return (
    <div className='header md:flex-row flex-col justify-evenly w-full '>
      <div className='image_sec md:h-[60%] md:w-1/2 sm:w-full md:mt-0 mt-12' data-aos="fade-up">
      <img src={future} alt="" className='title_text w-[100%] sm:w-[80%] sm:mt-28 md:w-[65%] md:h-[65%]' />
      </div>

      <div className='md:w-1/2 w-[100%]' data-aos='fade-up'>
      <img src={header_image} alt="" className='title_image md:w-[80%] md:h-[80%]' />
      </div>

     

    </div>




  )
}

export default Header
