import React,{useEffect , useState} from 'react'
import '../styles/Works.css';
import Aos from 'aos';
import "aos/dist/aos.css"
import {TiChevronRight} from "@react-icons/all-files/ti/TiChevronRight";
import {TiChevronLeft} from "@react-icons/all-files/ti/TiChevronLeft";

import web_1 from '../images/web-1.svg'
import web_mob_1 from '../images/web-mob-1.png'
import web_2 from '../images/web-mockup-2.svg'

function Works() {

  useEffect(() => {
    Aos.init({duration:2000})
  }, [])

  const works = [
    {img:web_1, title:'WE DU', mob_img:web_mob_1 ,desc:'A web application for WE DU company containing many animations and intractive design' },
    {img:web_2, mob_img:web_mob_1 , title:'EXample 1', desc:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
    {img:web_1, mob_img:web_mob_1 , title:'EXample 2', desc:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.' },

  ]

  const [work, setwork] = useState(0);

  const change_works_right = ()=>{
    if (work < 2) {
      setwork(work + 1)
    } else if (work === 2) {
      setwork(0)
    }
  }

  const change_works_left = ()=>{
    if (work === 0) {
      setwork(2)
    } else if (work > 0) {
      setwork(work - 1)
    }
  }

  

  return (
   
    <div className='works relative md:h-[100vh] sm:h-[45vh] h-[70vh] w-full flex flex-col justify-start items-center z-0' id='Works'>
        
        {/* <p className='text-3xl font-bold text-gray-500 mb-16'>Works</p> */}
        <div>
          
        </div>

        <div className='image md:h-[75%] md:w-[70%] sm:w-[80%] sm:h-[60%] flex justify-center items-center' data-aos="fade-up">
            <img src={works[work].img} alt="" className='hidden md:flex sm:flex md:rounded-lg sm:rounded-lg rounded-t-lg md:h-[100%] md:w-[100%] sm:h-[100%] sm:w-[100%] w-[90%]'/>
            <img src={works[work].mob_img} alt="" className='md:hidden sm:hidden rounded-t-lg w-[94%]'/>
        </div>
           <div className='text p-4 md:absolute sm:absolute md:top-[480px] sm:top-[240px] md:h-1/5  md:w-1/3 sm:h-1/5 sm:w-1/3 w-[90%]  md:rounded-lg sm:rounded-lg rounded-b-lg z-10 flex flex-col justify-around items-center'> 
             <p className='title md:text-2xl sm:text-lg'><a href="#" className='underline' >{works[work].title}</a></p>
             <p className='desc md:text-lg sm:text-sm'>{works[work].desc}</p>
        </div>
           <div className='z-10 flex flex-row w-[50%] justify-between items-center'>
             <button type="button" className='cursor-pointer text rounded-lg mt-2' onClick={change_works_left}><TiChevronLeft  className='h-12 w-12 text-white'/></button>
             <button type="button" className='cursor-pointer text rounded-lg mt-2' onClick={change_works_right}><TiChevronRight className='h-12 w-12 text-white'/></button>
           </div> 
    </div>
    


  )
}

export default Works




