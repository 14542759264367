import './styles/App.css';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Services from './components/Services';
import { useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css"
import Footer from './components/Footer';
import Future from './components/Future';
import Works from './components/Works';
import Contact from './components/Contact';
import About from './components/About';

// primary color #6b41a7
// secondary color #9768D1
function App() {

  useEffect(() => {
    Aos.init({duration:2000})
  }, [])
  

  return (
    <div className="App">
      
      <Navbar/>
      <Header/>
      <Services/>
      <Works/>
      <Future/>
      <About/>
      {/* <Contact/> */}
      <Footer/>

      
    
    </div>  
  );
}

export default App;
