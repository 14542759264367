import React,{useEffect , useState} from 'react'
import '../styles/Works.css';
import Aos from 'aos';
import "aos/dist/aos.css"
import team from'../images/team.svg'

function About() {

  useEffect(() => {
    Aos.init({duration:2000})
  }, [])

  return (
   

    <div className='md:h-[100vh] sm:h-[90vh] h-[80vh] w-full flex flex-col-reverse md:flex-row justify-evenly items-center' id='About'>

        <div className='md:w-2/5 md:h-1/4 p-5 flex flex-col justify-around items-center h-1/3 sm:h-1/4 w-full'>
            <p className='text-3xl font-bold text-[#8200ff]'>ABOUT US</p>
            <p className='text-xl'>NMY is a Technological company that has been founded in 2023 by a group of professional developers and designers to serve more. </p>
        </div>

        <img src={team} alt="" className='md:w-1/2 w-[90%]'/>

        
    </div>


  )
}

export default About