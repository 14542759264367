import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css"
import  future from '../images/FUTURE-HERE.svg'
import '../styles/future.css'
function Future() {

  useEffect(() => {
    Aos.init({duration:2000})
  }, [])
  

  return (
    <div className='w-full flex justify-center items-center h-auto future mt-8'>
      <div className='w-[100%] sm:w-[70%] md:w-[80%]' data-aos="fade-up ">
      <img src={future} alt="" className=' w-[100%]' />
      </div>
    </div>
  )
}

export default Future
