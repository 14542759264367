import React, {useState ,useEffect} from 'react'
import '../styles/nav.css'
import logo_purple from '../images/nmy_logo_puple-white.svg'
import logo_white from '../images/nmy_logo_white-black.svg'
import Aos from 'aos'
import { HiMenu } from "@react-icons/all-files/hi/HiMenu";
import {IoMdClose} from "@react-icons/all-files/io/IoMdClose";





function Navbar() {
  useEffect(() => {
    Aos.init({duration:2000})
  }, [])
  const [nav, setnav] = useState(false)

  const [mob_nav, setmob_nav] = useState(true);

  const changeNav = () =>{
    if (window.scrollY >= 100) {
      setnav(true)
    } else {
      setnav(false)
    }
  }

  window.addEventListener('scroll' , changeNav)
  
  const changeMob_nav = () =>{
    setmob_nav(!mob_nav)
    console.log(mob_nav);
  }
  
  return (
    
    <div className='w-full h-full'>
      
   
    <div className='nav_all md:flex hidden z-20'  >
    <div className={nav ? 'nav nav_bg' : 'nav'} data-aos='fade-down' >
        <div className='logo_sec'> 
        <img src={nav ? logo_purple : logo_white  } alt="logo" className='logo'/>
        </div>
      <ul className='nav_items'>
      <li className='nav_item font-bold md:text-lg'><a href="#services" className=''>SERVICES</a> </li>
      <li className='nav_item font-bold md:text-lg'> <a href="#Works"> WORKS </a></li>
      <li className='nav_item font-bold md:text-lg'> <a href="#About"> ABOUT</a></li>
      <li className='nav_item font-bold md:text-lg'> <a href="#Contact"> CONTACT</a></li>
      <li>
            <select className="p-2.5 text-[#8200ff] bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600">
                <option>کوردی</option>
                <option>ENGLISH</option>
                <option>عربية</option>
            </select>
      </li>
      </ul>
    </div>
    </div>

    <div className={' fixed flex justify-start items-start w-[100%] z-10 md:hidden'}  data-aos='fade-down' >
      
      <div className={mob_nav ?  'flex justify-around md:hidden mob-nav items-center flex-row h-16 w-full z-10 sm:h-20' :'hidden sm:hidden' }>
      <img src={logo_white  } alt="logo" className='h-10 w-10 sm:h-16 sm:w-16 '/>
      <button type="button" onClick={changeMob_nav} ><HiMenu className='w-6 h-6 text-white sm:h-10 sm:w-10' /></button>
      </div>
    <ul className={mob_nav ? 'sm:hidden hidden' : 'md:hidden sm:h-full sm:w-[100%] h-full w-[100%] mob-nav flex flex-col justify-start pb-8 items-center  z-20' }>
      <div className={'flex justify-around md:hidden items-center flex-row h-16 w-full z-10 sm:h-20' }>
         <img src={logo_white  } alt="logo" className='h-10 w-10 sm:h-16 sm:w-16 '/>
         <button type="button" onClick={changeMob_nav} className=''><IoMdClose className='w-6 h-6 text-white sm:h-10 sm:w-10 '/></button>
      </div>
      <li className='text-[#8200ff] text-xl mb-2 sm:text-3xl rounded-md bg-white p-4 w-2/3' ><a href="#services" className=''>SERVICES</a> </li>
      <li className='text-[#8200ff] text-xl mb-2 sm:text-3xl rounded-md bg-white p-4 w-2/3' > <a href="#"> WORKS </a></li>
      <li className='text-[#8200ff] text-xl mb-2 sm:text-3xl rounded-md bg-white p-4 w-2/3' > <a href="#"> ABOUT</a></li>
      <li className='text-[#8200ff] text-xl mb-2 sm:text-3xl rounded-md bg-white p-4 w-2/3' > <a href="#"> CONTACT</a></li>
      <li className='text-[#8200ff] text-xl mb-2 sm:text-3xl rounded-md bg-white w-2/3'>
            <select className="p-4 text-[#8200ff] w-full sm:text-3xl sm:w-full text-center bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600">
                <option className='text-sm'>ENGLISH</option>
                <option className='text-sm'>کوردی</option>
                <option className='text-sm'>عربية</option>
            </select>

      </li>

      </ul>


    </div>





    </div>
  )
}

export default Navbar
